import Vue from 'vue';
import { Mixin } from 'vue-mixin-decorator';
import UserService from '@/services/UserService';

@Mixin
export default class LuanchGameMixin extends Vue {
  public banks: Array<any> = [];

  public userService = new UserService();

  // eslint-disable-next-line consistent-return
  public async luanchGame(gameId?: string): Promise<string | undefined> {
    try {
      const { data } = await this.userService.luanchGame();
      return `https://www.stardna.com/v1/games?game_id=${gameId || ''}&token=${data.result.token}`;
    } catch (e) {
      console.log(e);
      this.$store.commit('Snackbar/showNotification', { message: 'เกิดข้อผิดพลาดระหว่างเปิดเกม', error: true });
    }
  }
}
