




import {
  Vue, Prop, Emit,
} from 'vue-property-decorator';
// eslint-disable-next-line import/no-cycle
import LunchGameMixin from '@/mixins/luanchGameMixin';
import { Mixins, Component } from 'vue-mixin-decorator';

@Component
// eslint-disable-next-line max-len
export default class PlayGame extends Mixins<LunchGameMixin>(LunchGameMixin) {
  public gameUrl: string = '';

  // eslint-disable-next-line class-methods-use-this
  async created() {
    const data = await this.luanchGame();
    this.gameUrl = data || '';
  }
}
